import { useRouteError } from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';

/**
 * Sends the route error to Sentry
 */
export function DefaultErrorBoundary({ children }: { children: React.ReactNode }) {
	const error = useRouteError();
	captureRemixErrorBoundaryError(error);

	if (typeof window !== 'undefined' && window.GLOBALS.environment === 'development') {
		console.error(error);
	}

	return children;
}
